import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import { Container } from "react-bootstrap"
import PrivacyPolicyText from "../components/markdown/privacy-policy"
import React from "react"
import Footer from "../components/footer"

const PrivacyPolicy = ({ location }) => {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <NavBar location={location} />
      <Container className="pb-5">
        <PrivacyPolicyText />
        <Footer />
      </Container>
    </Layout>
  )
}

export default PrivacyPolicy
